exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-business-articles-js": () => import("./../../../src/pages/business/articles.js" /* webpackChunkName: "component---src-pages-business-articles-js" */),
  "component---src-pages-business-contact-js": () => import("./../../../src/pages/business/contact.js" /* webpackChunkName: "component---src-pages-business-contact-js" */),
  "component---src-pages-business-cooperation-js": () => import("./../../../src/pages/business/cooperation.js" /* webpackChunkName: "component---src-pages-business-cooperation-js" */),
  "component---src-pages-business-faq-js": () => import("./../../../src/pages/business/faq.js" /* webpackChunkName: "component---src-pages-business-faq-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-business-map-js": () => import("./../../../src/pages/business/map.js" /* webpackChunkName: "component---src-pages-business-map-js" */),
  "component---src-pages-contact-details-js": () => import("./../../../src/pages/contact-details.js" /* webpackChunkName: "component---src-pages-contact-details-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-formularz-leadowy-js": () => import("./../../../src/pages/formularz-leadowy.js" /* webpackChunkName: "component---src-pages-formularz-leadowy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konferencja-js": () => import("./../../../src/pages/konferencja.js" /* webpackChunkName: "component---src-pages-konferencja-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-nexera-regions-js": () => import("./../../../src/pages/nexera-regions.js" /* webpackChunkName: "component---src-pages-nexera-regions-js" */),
  "component---src-pages-operator-articles-js": () => import("./../../../src/pages/operator/articles.js" /* webpackChunkName: "component---src-pages-operator-articles-js" */),
  "component---src-pages-operator-contact-js": () => import("./../../../src/pages/operator/contact.js" /* webpackChunkName: "component---src-pages-operator-contact-js" */),
  "component---src-pages-operator-cooperation-js": () => import("./../../../src/pages/operator/cooperation.js" /* webpackChunkName: "component---src-pages-operator-cooperation-js" */),
  "component---src-pages-operator-faq-js": () => import("./../../../src/pages/operator/faq.js" /* webpackChunkName: "component---src-pages-operator-faq-js" */),
  "component---src-pages-operator-index-js": () => import("./../../../src/pages/operator/index.js" /* webpackChunkName: "component---src-pages-operator-index-js" */),
  "component---src-pages-operator-info-js": () => import("./../../../src/pages/operator/info.js" /* webpackChunkName: "component---src-pages-operator-info-js" */),
  "component---src-pages-operator-offer-js": () => import("./../../../src/pages/operator/offer.js" /* webpackChunkName: "component---src-pages-operator-offer-js" */),
  "component---src-pages-operator-wallet-js": () => import("./../../../src/pages/operator/wallet.js" /* webpackChunkName: "component---src-pages-operator-wallet-js" */),
  "component---src-pages-our-network-js": () => import("./../../../src/pages/our-network.js" /* webpackChunkName: "component---src-pages-our-network-js" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-popc-js": () => import("./../../../src/pages/popc.js" /* webpackChunkName: "component---src-pages-popc-js" */),
  "component---src-pages-raport-2019-js": () => import("./../../../src/pages/raport2019.js" /* webpackChunkName: "component---src-pages-raport-2019-js" */),
  "component---src-pages-regionynexery-2020-js": () => import("./../../../src/pages/regionynexery2020.js" /* webpackChunkName: "component---src-pages-regionynexery-2020-js" */),
  "component---src-pages-school-articles-js": () => import("./../../../src/pages/school/articles.js" /* webpackChunkName: "component---src-pages-school-articles-js" */),
  "component---src-pages-school-contact-js": () => import("./../../../src/pages/school/contact.js" /* webpackChunkName: "component---src-pages-school-contact-js" */),
  "component---src-pages-school-faq-js": () => import("./../../../src/pages/school/faq.js" /* webpackChunkName: "component---src-pages-school-faq-js" */),
  "component---src-pages-school-index-js": () => import("./../../../src/pages/school/index.js" /* webpackChunkName: "component---src-pages-school-index-js" */),
  "component---src-pages-school-map-js": () => import("./../../../src/pages/school/map.js" /* webpackChunkName: "component---src-pages-school-map-js" */),
  "component---src-pages-school-support-js": () => import("./../../../src/pages/school/support.js" /* webpackChunkName: "component---src-pages-school-support-js" */),
  "component---src-templates-articles-template-single-article-page-index-js": () => import("./../../../src/templates/ArticlesTemplate/SingleArticlePage/index.js" /* webpackChunkName: "component---src-templates-articles-template-single-article-page-index-js" */),
  "component---src-templates-articles-template-tagged-articles-page-index-js": () => import("./../../../src/templates/ArticlesTemplate/TaggedArticlesPage/index.js" /* webpackChunkName: "component---src-templates-articles-template-tagged-articles-page-index-js" */),
  "component---src-templates-custom-subpage-template-index-js": () => import("./../../../src/templates/CustomSubpageTemplate/index.js" /* webpackChunkName: "component---src-templates-custom-subpage-template-index-js" */),
  "component---src-templates-personnel-page-template-index-js": () => import("./../../../src/templates/PersonnelPageTemplate/index.js" /* webpackChunkName: "component---src-templates-personnel-page-template-index-js" */)
}

